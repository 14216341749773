import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 1.5rem;
  background: ${(props) => props.theme.palette.themeColorsPrimary};
  color: ${(props) => props.theme.palette.onColorsOnPrimary};
  font-size: 11px;
  text-align: center;
  position: relative;
  z-index: 1050;
`;

const Footer: React.FC = () => {
  return (
    <footer>
      <Container id="mut-footer">
        &copy; {new Date().getFullYear()} MLB Advanced Media, LP. All Rights Reserved
      </Container>
    </footer>
  );
};

export default Footer;
