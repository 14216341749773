import { ASC, SortDirection } from "best-common-react";
import { MUTQuery } from "../types/Files";
import { HelpDescriptionDTO } from "../types/Help";
import { CacheRefreshTimeDTO, DugoutFileType, GroupDTO } from "../types/Metadata";
import { AbstractMutPackageDTO, MutPackageDTO, PackageTablePage } from "../types/Packages";
import { Pageable } from "../types/Pageable";
import {
  PressboxDownloadCategoryDTO,
  PressboxDownloadSubCategoryDTO,
  PressboxDownloadTypeDTO,
} from "../types/Pressbox";
import { PackageReportingDTO, PackageReportingFilters } from "../types/Reports";
import { GCSUploadDTO, UploadDTO, UploadType, UploadTypeCategoriesDTO } from "../types/Uploads";
import { AppUserDTO, BulkAppUserUploadDTO, MutUserInfo, UserQuery, UserRole } from "../types/User";
import MUTClient from "./MUTClient";

export const getCacheRefreshTime = async (): Promise<CacheRefreshTimeDTO> =>
  (await MUTClient.get(`/metadata/cache/time`)).data.entity;

export const getUserInfo = async (): Promise<MutUserInfo> => (await MUTClient.get(`/user/info`)).data.entity;

export const userLoggedIn = async (): Promise<any> => (await MUTClient.put(`/user/loggedIn`)).data;

export const getUploadCategories = async (groupId?: number): Promise<UploadTypeCategoriesDTO[]> => {
  const query = !!groupId ? `?groupId=${groupId}` : "";
  return (await MUTClient.get(`/uploads/categories${query}`)).data.entity;
};

export const uploadPackage = async (uploadDTO: UploadDTO): Promise<MutPackageDTO> =>
  (await MUTClient.post(`/uploads/package`, uploadDTO)).data.entity;

export const uploadFile = async (
  packageId: number,
  file: File,
  onUploadProgress?: (progressEvent: any) => void,
): Promise<MutPackageDTO> => {
  const formData = new FormData();
  formData.append("file", file);
  return (
    await MUTClient.post(`/uploads/file/${packageId}`, formData, {
      onUploadProgress,
    })
  ).data.entity;
};

export const getUploadTypes = async (): Promise<UploadType[]> =>
  (await MUTClient.get(`/metadata/uploadTypes`)).data.entity;

export const getUserRoles = async (): Promise<UserRole[]> => (await MUTClient.get(`/metadata/userRoles`)).data.entity;

export const getAllGroups = async (): Promise<GroupDTO[]> => (await MUTClient.get(`/metadata/groups`)).data.entity;

export const fetchFiles = async (
  query: MUTQuery,
  page: number,
  size: number,
  sortDirection: SortDirection | undefined = ASC,
  sortBy?: string,
): Promise<PackageTablePage<any>> => {
  const sortQuery = !!sortDirection && !!sortBy ? `&sortDirection=${sortDirection}&sortBy=${sortBy}` : ``;
  return (await MUTClient.post(`/files?page=${page}&size=${size}${sortQuery}`, query)).data.entity;
};

export const deleteFile = async (fileId: number): Promise<any> =>
  (await MUTClient.delete(`/files/${fileId}`)).data.entity;

export const stopFileUpload = async (fileResourceId: number): Promise<any> =>
  (await MUTClient.delete(`/files/stopUpload/${fileResourceId}`)).data.entity;

export const getAllUsers = async (
  query: UserQuery,
  page: number,
  size: number,
  sortDirection: SortDirection | undefined = ASC,
  sortBy?: string,
): Promise<Pageable<AppUserDTO>> => {
  const sortQuery = !!sortDirection && !!sortBy ? `&sortDirection=${sortDirection}&sortBy=${sortBy}` : ``;
  return (await MUTClient.post(`/admin/user/fetch?page=${page}&size=${size}${sortQuery}`, query)).data.entity;
};

export const saveUser = async (user: AppUserDTO): Promise<AppUserDTO> =>
  (await MUTClient.post(`/admin/user`, user)).data.entity;

export const bulkUploadUser = async (users: BulkAppUserUploadDTO): Promise<AppUserDTO[]> =>
  (await MUTClient.post(`/admin/user/bulk`, users)).data.entity;

export const downloadBulkTemplate = async (onDownloadProgress?: (progressEvent: any) => void): Promise<any> =>
  await MUTClient.get(`/admin/user/bulk/template`, {
    responseType: "blob",
    onDownloadProgress,
  });

export const deleteUser = async (userId: number): Promise<AppUserDTO> =>
  (await MUTClient.delete(`/admin/user/${userId}`)).data.entity;

export const getPackage = async (packageId: number): Promise<MutPackageDTO> =>
  (await MUTClient.get(`/package/${packageId}`)).data.entity;

export const deletePackageById = async (packageId: number): Promise<any[]> =>
  (await MUTClient.delete(`/package/${packageId}`)).data.entity;

export const downloadPackageById = async (
  packageId: number,
  onDownloadProgress?: (progressEvent: any) => void,
): Promise<any> =>
  await MUTClient.get(`/package/${packageId}/download`, {
    responseType: "blob",
    onDownloadProgress,
  });

export const downloadFileById = async (
  fileResourceId: number,
  onDownloadProgress?: (progressEvent: any) => void,
): Promise<any> =>
  await MUTClient.get(`/files/${fileResourceId}/download`, {
    responseType: "blob",
    onDownloadProgress,
  });

export const updatePackage = async (mutPackage: MutPackageDTO | AbstractMutPackageDTO): Promise<MutPackageDTO> =>
  (await MUTClient.put(`/package/${mutPackage.packageId}`, mutPackage)).data.entity;

export const adminGetAllGroups = async (): Promise<GroupDTO[]> =>
  (await MUTClient.get(`/admin/groups/all`)).data.entity;

export const saveGroup = async (groupDTO: GroupDTO): Promise<GroupDTO> =>
  (await MUTClient.post(`/admin/groups`, groupDTO)).data.entity;

export const deleteGroup = async (groupId: number): Promise<any> =>
  (await MUTClient.delete(`/admin/groups/${groupId}`)).data.entity;

export const getHelpDescription = async (): Promise<HelpDescriptionDTO> => (await MUTClient.get(`/help`)).data.entity;

export const updateHelpDescription = async (helpDescriptionDTO: HelpDescriptionDTO): Promise<HelpDescriptionDTO> =>
  (await MUTClient.post(`/admin/help`, helpDescriptionDTO)).data.entity;

export const sendUploadNotification = async (mutPackageId: number): Promise<any> =>
  (await MUTClient.post(`/uploads/sendNotification/${mutPackageId}`)).data.entity;

export const getAllDownloadTypes = async (): Promise<PressboxDownloadTypeDTO[]> =>
  (await MUTClient.get(`/pressbox/download/types`)).data.entity;

export const saveDownloadType = async (value: PressboxDownloadTypeDTO): Promise<PressboxDownloadTypeDTO> =>
  (await MUTClient.post(`/pressbox/download/types`, value)).data.entity;

export const saveAllDownloadTypes = async (values: PressboxDownloadTypeDTO[]): Promise<PressboxDownloadTypeDTO> =>
  (await MUTClient.post(`/pressbox/download/types/all`, values)).data.entity;

export const saveDownloadCategory = async (value: PressboxDownloadCategoryDTO): Promise<PressboxDownloadCategoryDTO> =>
  (await MUTClient.post(`/pressbox/download/category`, value)).data.entity;

export const saveAllDownloadCategories = async (
  values: PressboxDownloadCategoryDTO[],
): Promise<PressboxDownloadCategoryDTO> =>
  (await MUTClient.post(`/pressbox/download/category/all`, values)).data.entity;

export const saveDownloadSubCategory = async (
  value: PressboxDownloadSubCategoryDTO,
): Promise<PressboxDownloadSubCategoryDTO> =>
  (await MUTClient.post(`/pressbox/download/subcategory`, value)).data.entity;

export const saveAllDownloadSubCategories = async (
  values: PressboxDownloadSubCategoryDTO[],
): Promise<PressboxDownloadSubCategoryDTO> =>
  (await MUTClient.post(`/pressbox/download/subcategory/all`, values)).data.entity;

export const getDugoutStatsTeams = async (): Promise<GroupDTO[]> =>
  (await MUTClient.get(`/metadata/dugoutStatsTeams`)).data.entity;

export const getDugoutFileTypes = async (): Promise<DugoutFileType[]> =>
  (await MUTClient.get(`/metadata/dugoutFileTypes`)).data.entity;

export const getSignedUploadUrl = async (gcsUploadDTO: GCSUploadDTO): Promise<GCSUploadDTO> =>
  (await MUTClient.post(`/uploads/file/${gcsUploadDTO.packageId}/uploadGCSFile`, gcsUploadDTO)).data.entity;

export const sendUploadCompleteCallback = async (gcsUploadDTO: GCSUploadDTO): Promise<GCSUploadDTO> =>
  (await MUTClient.post(`/uploads/file/uploadSuccessfulCallback`, gcsUploadDTO)).data.entity;

export const getPackagesReport = async (
  packageReportingFilters: PackageReportingFilters,
): Promise<PackageReportingDTO> =>
  (await MUTClient.post(`/admin/reports/packages`, packageReportingFilters)).data.entity;
