export const LocalStorageConstants = {
  OKTA_TOKEN: "okta-token-storage",
  OKTA_CACHE_STORAGE: "okta-cache-storage",
  OKTA_URI_STORAGE: "okta-original-uri-storage",
  OKTA_TX_STORAGE: "okta-shared-transaction-storage",
  GROUPS: "mut-groups-metadata",
  UPLOAD_TYPES: "mut-upload-types-metadata",
  USER_ROLES: "mut-user-roles-metadata",
  USER_INFO: "mut-user-info-1",
  ORIGINAL_URI: "mut-original-uri",
  LAST_CACHE_TIME: "mut-last-cache-time",
  DUGOUT_FILE_TYPES: "dugout-file-types",
};
