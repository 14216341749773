import { NavigationConfigType, NavigationV2 } from "best-common-react";
import React from "react";
import logo from "../../assets/light-logo.png";
import { RouteConstants } from "../../constants/RouteConstants";
import { useMetadata } from "../../contexts/MetadataContext";
import { useRoles } from "../../contexts/RolesContext";
import { useUser } from "../../contexts/UserContext";

const { ENV: env } = window.MLBBest.envVariables || {};

const Navigation: React.FC = () => {
  const { refreshMetadata } = useMetadata();
  const { logout, loggedIn, userInfo } = useUser();
  const { isAdminUser } = useRoles();

  const config: NavigationConfigType = {
    env: env,
    builds: [],
    title: "MUT",
    displayEnvIndicator: env !== "prod",
    loggedIn: loggedIn,
    startLogo: {
      logo: logo,
      visible: loggedIn,
    },
    endLogo: {
      logo: "https://www.mlbstatic.com/team-logos/league-on-dark/1.svg",
      visible: loggedIn,
    },
    canToggleDarkMode: true,
    username: userInfo?.email,
    tabletSupport: false,
    usernameDropdownLinks: [
      {
        id: "refresh-metadata",
        name: "Refresh Metadata",
        onClick: () => refreshMetadata(true),
      },
      {
        id: "mut-sign-out",
        name: "Sign Out",
        onClick: logout,
      },
    ],
    navigationLinks: [
      {
        name: "Packages",
        icon: "fa-play-circle",
        iconCategory: "far",
        to: RouteConstants.PACKAGES,
        quickLink: true,
        visible: true,
      },
      {
        name: "Help",
        icon: "fa-question-circle",
        iconCategory: "far",
        to: RouteConstants.HELP,
        quickLink: true,
        visible: true,
      },
      {
        name: "Admin",
        visible: isAdminUser,
        icon: "fa-lock",
        iconCategory: "fas",
        quickLink: false,
        subLinks: [
          {
            name: "Users",
            icon: "fa-user",
            to: `${RouteConstants.ADMIN.BASE}/${RouteConstants.ADMIN.USERS}`,
            visible: isAdminUser,
          },
          {
            name: "Groups",
            icon: "fa-people-group",
            to: `${RouteConstants.ADMIN.BASE}/${RouteConstants.ADMIN.GROUPS}`,
            visible: isAdminUser,
          },
          {
            name: "Help",
            icon: "fa-question-circle",
            iconCategory: "far",
            to: `${RouteConstants.ADMIN.BASE}/${RouteConstants.ADMIN.HELP}`,
            visible: isAdminUser,
          },
          {
            name: "Pressbox Downloads",
            icon: "fa-newspaper",
            to: `${RouteConstants.ADMIN.BASE}/${RouteConstants.ADMIN.PRESSBOX_DOWNLOADS}`,
            visible: isAdminUser,
          },
        ],
      },
      {
        name: "Reports",
        icon: "fa-file",
        iconCategory: "fas",
        subLinks: [
          {
            name: "Packages Report",
            icon: "fa-play-circle",
            iconCategory: "far",
            to: `${RouteConstants.ADMIN.BASE}/${RouteConstants.ADMIN.REPORTS.BASE}/${RouteConstants.ADMIN.REPORTS.PACKAGES}`,
          },
        ],
      },
    ],
  };

  return <NavigationV2 config={config} />;
};

export default Navigation;
